.nav-tabs .nav-item {
  width: 100%;
}
.nav-tabs .nav-item .nav-link {
  color: #000000;
  text-transform: uppercase;
  font-family: auto;
  width: 100%;
  border-bottom: 3px solid #252525 !important;
}
.tab-title {
  font-size: 24px !important;
}
.mahostav-img {
  width: 44%;
}
.css-hlgwow {
  padding: 4px 10px !important;
}

/* mahostav-card css */
.mahostav-card {
  background-color: rgb(244, 154, 16);
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.mahostav-mini {
  font-size: 11px;
  color: #000;
}
.mahostav-name {
  font-size: 18px;
  letter-spacing: 0.6px;
  color: #000;
}
.mahostav-card-register-btn {
  background-color: #007bff;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.mahostav-name {
  color: #444343;
}

.mahostav-card-register-btn:hover {
  background-color: #0056b3;
}
.mahostav-dec {
  height: auto !important;
}

.video-mahostav {
  width: 100%;
  height: 580px;
}

.text-mahostav {
  outline-offset: 5px;
}
.about-video {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

/* Participent-register Modal css */
.modal {
  overflow: hidden !important;
  margin-bottom: 20px;
}
.modal-dialog-right {
  position: absolute;
  right: 0;
  margin-right: 0;
  width: 42%;
}
.modal-body-title {
  font-size: 26px !important;
  letter-spacing: 0.5px;
  font-weight: 400;
}
.register-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.mahostav-btn {
  margin-top: auto !important;
}
.modal.fade .modal-dialog-right {
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.modal.show .modal-dialog-right {
  transform: translateX(0);
}
.close-btn:active {
  border: none !important;
}
.register-modal-header {
  background-color: rgb(244, 154, 16);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.participnet-modal-body {
  padding: 25px 30px;
  margin-top: 70px;
  overflow: auto !important;
}
.participnet-modal-body::-webkit-scrollbar {
  display: none !important;
}
.modal-body-dec {
  font-size: 15px;
  letter-spacing: 0.4px;
  line-height: 26px;
}
.submit-participent-btn {
  padding: 15px 55px;
  border-radius: 5px;
  border: none;
}
.cancle-paricipent-btn {
  color: #000;
  padding: 15px 55px;
  border-radius: 5px;
  border: 1px solid #000;
}
.submit-mahostav {
  background-color: rgb(244, 154, 16);
  color: #000;
}
.disable {
  background-color: #585858;
}
