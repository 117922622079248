/* navbar */
.navbar-sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 99 !important;
}
.navbar {
  padding: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.navbar-bg {
  background-color: var(--main-color);
  color: #fff;
  padding: 10px 0px;
}
.nav-logo {
  width: 10%;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.navbar-nav li {
  position: relative;
}
.navbar-nav li a {
  color: #fff;
  text-decoration: none;
  padding: 5px 20px;
  position: relative;
  transition: color 0.3s ease;
}
.menu-link::before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  top: 90%;
  left: 0;
  background-color: #fff;
  transition: width 0.3s ease;
}
.menu-link:hover::before {
  width: 100%;
}

.abou-peta-menu li {
  border-bottom: 1px solid #fff;
}
.abou-peta-menu li:last-child {
  border-bottom: none;
}
.abou-peta-menu {
  padding: 5px;
  position: absolute;
  top: 100% !important;
  left: 0;
  width: 250px;
  border-radius: 5px;
  background-color: var(--main-color);
  display: none;
  border-bottom: 2px solid #18a790;
}
.abou-peta-menu li a {
  font-size: 12px;
  color: #fff !important;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.abou-peta-menu li:hover::before {
  width: 100%;
}

.navbar-nav li:hover .abou-peta-menu {
  display: block;
}
.navbar-nav li:hover .result-menu {
  display: block;
}
.navbar-nav li:hover .news-media {
  display: block;
}
.abou-peta-menu li:hover .about-petaone-menu {
  display: block;
}
.navbar-nav li:hover .philantropy-menu {
  display: block;
}
.philantropy-menu li:hover .cricket-leage {
  display: block;
}
.philantropy-menu li:hover .chairity-menu {
  display: block;
}
.about-petaone-menu {
  position: absolute;
  top: 0;
  left: 100%;
  width: 300px;
  background-color: var(--main-color);
  padding: 5px;
  border-radius: 5px;
  display: none;
  border-left: 2px solid #18a790;
}

.about-petaone-menu li {
  padding: 5px 0px;
}
.about-petaone-menu li a {
  font-size: 12px;
  color: #fff !important;
  padding: 10px;
  position: relative;
  overflow: hidden;
  display: block;
}

.about-petaone-menu li:last-child {
  padding-bottom: none !important;
  border: none;
}
.race-peta-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 180px;
  background-color: var(--main-color);
  display: none;
  transition: 0.3s ease;
  border-radius: 5px;
}
.race-peta-menu li {
  border-bottom: 1px solid #fff;
}
.race-peta-menu li:last-child {
  border-bottom: none;
}
.race-peta-menu {
  padding: 0;
}
.race-peta-menu li a {
  font-size: 12px;
  color: #fff !important;
  padding: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.race-peta-menu li a::before {
  content: "";
  width: 0;
  height: 2px;
  background-color: #18a790;
  position: absolute;
  top: 95%;
  left: 0;
  transition: 0.3s ease-in;
}
.race-peta-menu li:hover::before {
  width: 100%;
}

.navbar-nav li:hover .race-peta-menu {
  display: block;
}

/* register-offcanvas */
.off-register {
  height: 50vh;
}
.fundation-img {
  width: 60%;
  margin-bottom: 30px;
}
.register-main-title {
  font-size: 30px !important;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  line-height: 36px;
}
.register-main-dec {
  font-size: 12px !important;
  letter-spacing: 0.3px;
  text-align: justify;
  line-height: 20px;
}
.register-label {
  font-size: 14px;
  color: #585858;
  text-align: start;
  letter-spacing: 0.3px;
}
.register-tab {
  padding: 7px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
}
.register-btn {
  width: 100%;
  border: none;
  padding: 10px 0px;
  border-radius: 5px;
  background-color: var(--main-color);
  color: #fff;
}
.register-otp-title {
  font-size: 25px !important;
}

.resend-msg {
  font-size: 14px;
}
.resend-otp {
  text-decoration: none;
  font-size: 14px;
  border: none;
  color: #0d6efd !important;
  background: transparent;
}

.otp-input {
  width: 45px;
  height: 45px;
  border: 2px solid #dad9df;
  outline: none;
  text-align: center;
  font-size: 1.5em;
  border-radius: 0.3em;
  background-color: #ffffff;
  outline: none;
}

.philantropy-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: var(--main-color);
  color: #fff;
  display: none;
  padding: 0;
  border-radius: 5px;
  border-bottom: 2px solid #18a790;
}
.philantropy-menu li {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}
.cricket-leage li {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}
.chairity-menu li {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}
.bairgnia-mahostav li {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}
.maakalimandir li {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}
.result-menu li {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}
.news-media li {
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}

.philantropy-menu li:last-child {
  border-bottom: none;
}
.philantropy-menu li a {
  font-size: 12px;
  color: #fff !important;
  padding: 10px;
  position: relative;
  overflow: hidden;
  display: block;
}
.cricket-leage {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 250px;
  background-color: var(--main-color);
  color: #fff;
  padding: 0;
  border-left: 2px solid #18a790;
  border-radius: 5px;
}
.chairity-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 250px;
  background-color: var(--main-color);
  color: #fff;
  padding: 0;
  border-top: 2px solid #18a790;
  border-radius: 5px;
}

.bairgnia-mahostav {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 250px;
  background-color: var(--main-color);
  color: #fff;
  padding: 0;
  border-left: 2px solid #18a790;
  border-radius: 5px;
}
.philantropy-menu li:hover .bairgnia-mahostav {
  display: block;
}
.mahostav-peta-menu {
  position: absolute;
  top: 100%;
  left: 20%;
  background-color: var(--main-color);
  color: #fff;
  width: 250px;
  padding: 0;
  border-top: 2px solid #18a790;
  border-radius: 5px;
  display: none;
}
.bairgnia-mahostav li:hover .mahostav-peta-menu {
  display: block;
}
.maakalimandir {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 250px;
  background-color: var(--main-color);
  color: #fff;
  padding: 0;
  border-left: 2px solid #18a790;
  border-radius: 5px;
}
.chairity-menu li:hover .maakalimandir {
  display: block;
}
.result-menu,
.news-media {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: var(--main-color);
  color: #fff;
  padding: 0;
  border-bottom: 2px solid #18a790;
  border-radius: 5px;
}
.otp-input {
  width: 45px;
  height: 45px;
  margin: 0px 5px;
}
.otp-input input {
  width: 100% !important;
  height: 100% !important;
  box-shadow: none;
}
.loader-fad {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s ease-in-out;
  z-index: 9999;
}
.logout-btn {
  padding: 5px;
  position: absolute;
  top: 100% !important;
  right: 0 !important;
  width: 100px;
  border-radius: 5px;
  background-color: var(--main-color);
  display: none;
  border-bottom: 2px solid #18a790;
}
.navbar-nav li:hover .logout-btn {
  display: block;
  color: none;
}
.highlight {
  color: #0056b3;
  font-weight: bold;
  background-color: rgba(173, 216, 230, 0.5); 
  border-radius: 4px;
  letter-spacing: .4px;
  padding: 2px 6px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.highlight:hover {
  transform: scale(1.05); 
  background-color: rgba(173, 216, 230, 0.7); 
}