.cricket-btn {
  top: 3% !important;
  right: 2% !important;
  color: #fff !important;
}
.close-modal {
  font-size: 30px;
}

.cricket_modal {
  overflow: auto !important;
}
.cricket-header-text {
  line-height: 55px;
}
.header-logo{
  height: 650px;
}
/* General table styling */
.player_details {
  width: 1500px;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 10px;
  border-radius: 4px !important;
  overflow: hidden;
}
.name-feild-table {
  width: 300px;
}
.table-header {
  background-color: #422b69;
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #ddd;
}

.player-feild {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  vertical-align: middle;
}

.player-feild:first-child {
  background-color: #422b69;
  color: white;
  font-weight: bold;
}

.upload-demo {
  display: block;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #4a4a4a;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: #e7f0ff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.upload-demo:hover {
  background-color: #c6e0ff;
  color: #0056b3;
}

.jfUpload-wrapper {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #007bff;
  background-color: #fff;
  padding: 20px;
  transition: background-color 0.3s;
}

.jfUpload-wrapper:hover {
  background-color: #e8f0ff;
}

.jfUpload-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jfUpload-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.jfUpload-button:hover {
  background-color: #0056b3;
}

.jfUpload-files-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.form-upload-multiple {
  display: none;
}

.submit-cricket-form {
  color: #000;
  padding: 15px 55px;
  border-radius: 5px;
  border: 1px solid #000;
}
.submit-form {
  background-color: #0056b3;
  color: #fff;
  border: none;
}
.submit-disable{
  background-color: #888;
  color: #fff;
  border: none;
}

.bpl-logo{
  width: 25% !important;
}


.modal.fade {
  display: none;
}

.modal.show {
  display: block;
}