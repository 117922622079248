@media (max-width: 992px) {
  .bg-img {
    height: 50vh;
  }
  .marathn-map-tab {
    font-size: 12px !important;
    padding: 4px 12px !important;
  }
  .mearathon-map div {
    justify-content: center !important;
  }
  .map-container {
    justify-content: center !important;
  }
  .map-title {
    text-align: center !important;
  }
  .regular-dec {
    font-size: 15px !important;
    letter-spacing: 0.5px !important;
    line-height: 24px !important;
    word-spacing: 1px !important;
  }
  .nav-tabs .nav-item .nav-link {
    font-size: 12px !important;
  }
  .merathone-title {
    font-size: 20px !important;
    line-height: 35px !important;
  }
  .present {
    font-size: 14px !important;
  }
  .register-card {
    width: 100% !important;
  }
  .catagory-main {
    text-align: start !important;
  }
  .runnerkit-icons {
    justify-content: start !important;
  }
  .card-register-btn {
    width: 100%;
  }
  .rfees {
    font-size: 11px !important;
  }
  .total-paticipants p {
    font-size: 10px !important;
  }
  .register-footer-btn {
    padding: 4px 10px !important;
  }
  .btn-text,
  .r-fees-icon {
    font-size: 14px !important;
  }
  .offcanvas {
    margin-top: 14% !important;
  }
  .offcanvas-body-title {
    font-size: 22px !important;
  }
  .aboutus-main-title {
    font-size: 16px;
  }
  .main-about-list {
    width: 100%;
  }
  .aboutus {
    height: 100px;
  }
  .about-title {
    font-size: 14px;
  }

  .navbar-nav .nav-item {
    position: relative;
  }
  .navbar-nav .nav-item .abou-peta-menu {
    position: relative;
  }

  .navbar-nav .nav-item .abou-peta-menu,
  .navbar-nav .nav-item .race-peta-menu,
  .navbar-nav .nav-item .philantropy-menu,
  .navbar-nav .nav-item .result-menu,
  .navbar-nav .nav-item .news-media {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--main-color);
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    z-index: 1000;
    width: 100%;
  }

  .navbar-nav .nav-item .abou-peta-menu .about-petaone-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    background-color: var(--main-color);
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    z-index: 1000;
    width: 100%;
  }

  .navbar-nav .nav-item:hover .abou-peta-menu,
  .navbar-nav .nav-item:hover .race-peta-menu,
  .navbar-nav .nav-item:hover .philantropy-menu,
  .navbar-nav .nav-item:hover .result-menu,
  .navbar-nav .nav-item:hover .news-media,
  .navbar-nav .nav-item:hover .abou-peta-menu .about-petaone-menu,
  .navbar-nav .nav-item:hover .philantropy-menu .bairgnia-mahostav,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .bairgnia-mahostav
    .mahostav-peta-menu,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu .maakalimandir,
  .navbar-nav .nav-item:hover .philantropy-menu .cricket-leage,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu {
    display: block;
  }

  .navbar-nav .nav-item .abou-peta-menu li a,
  .navbar-nav .nav-item .abou-peta-menu .about-petaone-menu li a,
  .navbar-nav .nav-item .philantropy-menu li a,
  .navbar-nav .nav-item:hover .philantropy-menu .bairgnia-mahostav li a,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .bairgnia-mahostav
    .mahostav-peta-menu
    li
    a,
  .navbar-nav .nav-item:hover .philantropy-menu .cricket-leage li a,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu li a,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .chairity-menu
    .maakalimandir
    li
    a,
  .navbar-nav .nav-item .race-peta-menu li a,
  .navbar-nav .nav-item .result-menu li a,
  .navbar-nav .nav-item .news-media li a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    color: #fff;
  }

  .navbar-nav .nav-item .abou-peta-menu li a:hover,
  .navbar-nav .nav-item .abou-peta-menu .about-petaone-menu li a:hover,
  .navbar-nav .nav-item .philantropy-menu li a:hover,
  .navbar-nav .nav-item:hover .philantropy-menu .bairgnia-mahostav li a:hover,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .bairgnia-mahostav
    .mahostav-peta-menu
    li:hover,
  .navbar-nav .nav-item:hover .philantropy-menu .cricket-leage li a:hover,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu li a:hover,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .chairity-menu
    .maakalimandir
    li
    a:hover,
  .navbar-nav .nav-item .race-peta-menu li a:hover,
  .navbar-nav .nav-item .result-menu li a:hover,
  .navbar-nav .nav-item .news-media li a:hover {
    background-color: var(--main-color);
  }

  .navbar-nav .nav-item .abou-peta-menu,
  .navbar-nav .nav-item .abou-peta-menu .about-petaone-menu,
  .navbar-nav .nav-item .philantropy-menu,
  .navbar-nav .nav-item:hover .philantropy-menu .bairgnia-mahostav,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .bairgnia-mahostav
    .mahostav-peta-menu,
  .navbar-nav .nav-item:hover .philantropy-menu .cricket-leage,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu .maakalimandir,
  .navbar-nav .nav-item .race-peta-menu,
  .navbar-nav .nav-item .result-menu,
  .navbar-nav .nav-item .news-media {
    position: static;
    width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }

  .navbar-nav .nav-item .abou-peta-menu li,
  .navbar-nav .nav-item .abou-peta-menu .about-petaone-menu li,
  .navbar-nav .nav-item .philantropy-menu li,
  .navbar-nav .nav-item:hover .philantropy-menu .bairgnia-mahostav li,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .bairgnia-mahostav
    .mahostav-peta-menu
    li,
  .navbar-nav .nav-item:hover .philantropy-menu .cricket-leage li,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu li,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .chairity-menu
    .maakalimandir
    li,
  .navbar-nav .nav-item .race-peta-menu li,
  .navbar-nav .nav-item .result-menu li,
  .navbar-nav .nav-item .news-media li {
    border-bottom: 1px solid #ddd;
  }

  .navbar-nav .nav-item .abou-peta-menu li:last-child,
  .navbar-nav .nav-item .abou-peta-menu .about-petaone-menu li:last-child,
  .navbar-nav .nav-item .philantropy-menu li:last-child,
  .navbar-nav .nav-item .philantropy-menu .bairgnia-mahostav li:last-child,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .bairgnia-mahostav
    .mahostav-peta-menu
    li:last-child,
  .navbar-nav .nav-item:hover .philantropy-menu .cricket-leage li:last-child,
  .navbar-nav .nav-item:hover .philantropy-menu .chairity-menu li:last-child,
  .navbar-nav
    .nav-item:hover
    .philantropy-menu
    .chairity-menu
    .maakalimandir
    li:last-child,
  .navbar-nav .nav-item .race-peta-menu li:last-child,
  .navbar-nav .nav-item .result-menu li:last-child,
  .navbar-nav .nav-item .news-media li:last-child {
    border-bottom: none;
  }
  .navbar-collapse {
    overflow: auto !important;
    height: 295px;
  }
  .abou-peta-menu,
  .about-petaone-menu {
    position: static;
    width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }

  .abou-peta-menu li,
  .about-petaone-menu li {
    border-bottom: 1px solid #ddd;
  }

  .abou-peta-menu li:last-child,
  .about-petaone-menu li:last-child {
    border-bottom: none;
  }
  .text-chairman-title,
  .main-text-vicechairman-title {
    font-size: 16px !important;
  }
  .main-text-chairman,
  .main-text-vicechairman p {
    font-size: 14px !important;
  }
  .main-moa-title {
    font-size: 12px !important;
  }
  .list {
    font-size: 10px !important;
  }
  .aoa-text {
    font-size: 12px !important;
  }
  .chairmain p {
    font-size: 12px;
  }
  .regular-dec {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .register-main-title {
    font-size: 20px !important;
    letter-spacing: 0.3px;
    word-spacing: 1px;
    line-height: 36px;
  }
  .register-otp-title {
    font-size: 18px !important;
  }
  .register-main-dec {
    font-size: 12px !important;
    letter-spacing: 0.3px;
    text-align: justify;
    line-height: 20px;
  }
  .half-merathone-list-title {
    font-size: 16px !important;
  }
  .chairman-img {
    width: 40%;
    padding-left: 20px;
  }
  .chairman-demo {
    width: 50% !important;
    margin-bottom: 20px;
  }
  .moa-sec {
    margin-top: 100px;
  }
  .half-marathon-img {
    width: 25%;
  }
  .main-text-vicechairman-title {
    font-size: 18px !important;
    line-height: 24px !important;
  }
  .title-sponsoer {
    font-size: 14px !important;
  }
  .official-partner-title {
    font-size: 15px !important;
    width: 80% !important;
  }
  .official-partner {
    width: 30% !important;
  }
  .termsandcondition-header-text p {
    width: 100%;
    font-size: 14px;
  }
  .main-recipt-logo {
    width: 50% !important;
  }
  .table {
    width: 100% !important;
  }
  .payment-recipt {
    width: 100% !important;
  }
  .navbar-toggler {
    background-color: #fff !important;
  }
  .marathon-sub-title {
    top: 3%;
  }
  .marathon-sub-title-two {
    top: 4%;
  }
  .recipt-label-text {
    font-size: 12px;
  }
  .imp-note {
    font-size: 10px;
  }
  .location-icon {
    font-size: 35px !important;
  }
  .flage-img {
    width: 15%;
  }
  .flag-title {
    font-size: 15px !important;
    line-height: 40px !important;
  }
  .flag-List {
    flex-direction: column !important;
  }
  .indian-flag-img {
    width: 75% !important;
  }
  .quesation {
    font-size: 14px;
  }
  .header-img {
    width: 27% !important;
  }
  .modal-dialog {
    width: 100% !important;
  }
  .mahostav-card-register-btn {
    width: 100% !important;
  }
  .btn-close {
    position: absolute;
    top: 4%;
    right: 8%;
  }
  .btn-close {
    color: #fff !important;
  }
  .demo-icon {
    font-size: 34px !important;
  }
  .demo-icon-one {
    font-size: 28px !important;
  }
  .mahosatv-demo {
    flex-direction: column !important;
  }
  .mahosatv-demo span {
    width: 100%;
    text-align: left;
    padding: 0px 20px;
  }
  .responsive_modal {
    width: 100% !important;
  }
  /* cricket form */
  .cricket-header-text {
    font-size: 20px;
    line-height: 36px !important;
  }
  .form-subHeader {
    font-size: 16px;
    margin: 10px 0px;
  }
  .upload-demo {
    font-size: 16px;
  }
  .cricket-btn {
    top: 1% !important;
  }
  .cricket-leage-title{
    font-size: 14px !important;
  }

}
