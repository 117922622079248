.main-partner {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
}
.official-partner {
  width: 13% !important;
}

.official-partner-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 22px;
  font-family: auto !important;
  letter-spacing: 0.3px;
  width: 60% !important;
}

.official-partner-title::before,
.official-partner-title::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #000;
  margin: 0 10px;
  width: 70%;
}
.title-sponsoer {
  font-size: 18px !important;
  letter-spacing: 0.5px;
  line-height: 26px;
  font-family: auto;
}
.bob-img {
  width: 18% !important;
}
