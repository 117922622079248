.aboutus {
  /* background: linear-gradient(to right, #18a790 0, #3368b6 100%); */
  background-color: var(--main-color);
  height: 180px;
  margin-top: 5%;
}
.main-about-list {
  padding: 5px 20px;
  width: 75%;
}

.aboutus-main-title {
  font-size: 30px;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  color: #fff;
}
.aboutus-main-title sub {
  font-size: 12px;
}

.list {
  text-align: justify;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-size: 14px !important;
  color: var(--text-color);
  font-family: auto;
}
.moa-sec {
  margin-top: 150px;
}
.main-text-moa {
  text-align: justify;
}
.main-text-chairman {
  text-align: justify;
  letter-spacing: 0.3px;
  word-spacing: 0.8px;
  line-height: 28px;
  font-family: auto;
  font-weight: 500;
  font-size: 18px;
}
.aoa-text {
  text-align: justify !important;
  line-height: 20px !important;
  font-family: auto !important;
  font-weight: 500 !important;
}
.enlarged-letter {
  font-size: larger !important;
  margin-left: 20px !important;
}

.list-table li {
  text-align: justify;
  list-style: disc;
  margin-left: 20px;
}

.table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
  font-size: 14px;
}

.fw-semibold {
  font-weight: 600;
}

.list-table-one {
  list-style: disc !important;
  text-align: justify !important;
  margin-left: 20px !important;
  font-family: Arial !important;
  font-size: 14px;
  line-height: 25px !important;
  color: #000 !important;
}
.line {
  font-size: 20px !important;
}
.chairman-text {
  text-transform: uppercase !important;
}
/* half-merathone */
.half-marathone-list {
  list-style: disc;
}
.half-merathone-list-title {
  font-size: 25px !important;
  font-family: auto;
  font-weight: 600;
  line-height: 28px !important;
  border-left: 5px solid red;
}
.main-half-header {
  border-left: 10px solid red !important;
  padding: 8px 0px;
}
.main-text-vicechairman-title {
  font-size: 22px !important;
  font-family: auto;
  font-weight: 600;
}
.main-text-vicechairman p {
  font-family: auto;
}
.chairman-img {
  width: 20%;
}

.chairman-main-img {
  height: 350px !important;
  transform: rotateY(180deg) !important;
}
.chairman-title {
  border-left: 10px solid red;
}
.li,
p {
  font-family: Arial !important;
  font-size: 14px;
  line-height: 21px;
  color: #000;
}
.half-marathon-img {
  width: 16%;
}
/* .half-mearthone-header-text {
  border-left: 5px solid red;
} */
.header-img {
  width: 13% !important;
}
