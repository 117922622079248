.contactus-input,
.contactus-text {
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contactus-input:focus,
.contactus-text:focus {
  border-color: #ff6f61;
  box-shadow: 0 0 8px rgba(255, 111, 97, 0.3);
}

.contactus-text {
  resize: none;
}

.contactus-card {
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.contactus-card-form label {
  color: #444;
  font-weight: 600;
}

.contactus-img img {
  border-radius: 10px;
}

.contactus-card button {
  padding: 10px 20px;
  background-color: #ff6f61;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-contact {
  background-color: lightskyblue;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
