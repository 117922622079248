:root {
  --main-color: #e41c3c;
  --main-text-color: #ffffff;
  --card-btn-color: #ef7f1a;
  --text-color: #585858;
}
::selection {
  background-color: #e41c3c;
  color: #fff;
}
ul {
  list-style: none;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.offcanvas-backdrop .fade .show {
  display: none !important;
}
.demo-title {
  font-size: 28px !important;
  font-family: auto;
  font-weight: 600;
}
.demo-run-dec {
  font-size: 16px !important;
  font-family: auto !important;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #e41c3c;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}
