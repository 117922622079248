.logo-section {
  width: 20%;
}
.main-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  background-color: #fff;
  border-top: 1px solid #ccc;
}

footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

footer .col-md-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

footer h5 {
  margin-bottom: 1rem;
  font-weight: bold;
}

footer ul {
  list-style-type: none;
  padding: 0;
}

footer ul .nav-item {
  margin-bottom: 0.5rem;
}

footer a {
  text-decoration: none;
  color: inherit;
}

footer a:hover {
  text-decoration: underline;
}
.location-icon {
  font-size: 40px;
}
.footer-address {
  letter-spacing: 1px;
  word-spacing: 0.3px;
  font-weight: 500;
}
.footer-call-txt{
  font-size: 14px;
}