/* .top-header {
  position: fixed;
  top: 30% !important;
  left: 0;
  height: 200px;
  width: 3%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 12px 5px;
  border-radius: 5px;
  z-index: 99;
} */
.top-header-menu {
  flex-direction: row;
}
.faqs a {
  font-size: 12px;
}
.icon {
  font-size: 20px;
  transition: 0.3s;
  color: #fff;
}
.media-icons {
  padding: 5px 10px;
  border-radius: 5px;
}
.facebook {
  background-color: #1877f2;
}
.insta {
  background-color: #e1306c;
}
.you-tube {
  background-color: #ff0000;
}
.twitter {
  background-color: #000000;
}
