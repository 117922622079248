.mearathon {
  padding: 30px 20px;
  margin-bottom: 80px;
  margin-top: 80px;
}
.merathone-title {
  font-size: 25px !important;
  line-height: 35px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  background: var(--main-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.present {
  font-size: 16px !important;
  line-height: 18px;
  font-weight: 500;
}

.nav-tabs .nav-item .nav-link {
  border: none;
  color: var(--text-color);
  letter-spacing: 0.6px;
  font-weight: 700;
  font-size: 18px !important;
  border-bottom: 3px solid #252525 !important;
  text-transform: uppercase;
  font-family: auto;
}

.tab-title {
  color: #000;
  font-family: auto;
  font-weight: 600;
}

.regular-dec {
  color: #000;
  font-size: 14px;
  line-height: 24px !important;
  letter-spacing: 0.6px !important;
  word-spacing: 2.3px !important;
  font-weight: 300;
  height: 240px;
  /* overflow: hidden; */
  text-align: justify;
  font-family: auto;
}
.show-less {
  /* max-height: 240px; */
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
}

.show-all {
  height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
}

.show-more-btn {
  background-color: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
}

.text-center {
  text-align: center;
}
.map-title {
  text-align: end !important;
  font-size: 15px !important;
  font-weight: 600;
}
.pagination {
  background-color: #f1f1f1;
  color: var(--text-color);
  justify-content: space-around;
}

.marathn-map-tab {
  border: none;
  letter-spacing: 0.3px !important;
  font-size: 14px !important;
  padding: 7px 14px !important;
  width: 100%;
  height: 100%;
}
.first-menu {
  background-color: #ccc;
}
.card-title {
  font-size: 14px;
  color: #252525;
}
.tab-active .marathn-map-tab {
  background-color: var(--main-color);
  color: var(--main-text-color);
}
.map-container {
  justify-content: end;
}
.merathone-card {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-mini {
  font-size: 11px;
  color: #fff;
}
.category-name {
  font-size: 18px;
  letter-spacing: 0.6px;
  color: #fff;
}
.main-register-btn {
  padding: 10px 0px;
}
.icon {
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}

.card-register-btn {
  background-color: var(--card-btn-color);
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.r-icon {
  font-size: 18px;
}

.register-card-footer {
  background-color: rgba(239, 239, 239, 255);
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 2px 10px;
}
.participets,
.view-participants {
  font-size: 14px;
  color: #252525;
}
.register-footer-btn {
  background-color: var(--main-color);
  padding: 8px 14px;
  letter-spacing: 0.3px;
}
.marathon-footer {
  padding: 12px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 #000000;
}
.rfees {
  font-size: 16px !important;
}
.total-paticipants p {
  font-size: 16px !important;
}
.offcanvas,
.register-canvas {
  width: 500px !important;
  margin-top: calc(100% - 97.2%) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.register-canvas {
  width: 400px !important;
}
.offcanvas-header {
  background-color: var(--main-color);
  color: var(--main-text-color);
}
.offcanvas-header .btn-close {
  background-color: #fff;
}

.offcanvas-body {
  padding: 25px 30px !important;
  overflow: auto !important;
}
.offcanvas-body::-webkit-scrollbar {
  display: none !important;
}
.offcanvas-body-title {
  font-size: 36px !important;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.offcanvas-body-dec {
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 24px;
}
.merathone-details {
  color: var(--text-color);
}
.race-details {
  font-size: 14px;
  color: var(--text-color);
}
.race-category {
  width: 100%;
  padding: 10px 7px;
  border-radius: 5px;
  border: 1px solid #8888 !important;
}
.action-btn {
  background: transparent;
  border: 1px solid #585858;
  padding: 15px 55px;
  border-radius: 5px;
}
.submit {
  background-color: var(--main-color);
  border: none;
}
.check-text {
  font-size: 12px;
}
.check-link {
  color: #337ab7;
}
textarea {
  border: 1px solid #888;
  letter-spacing: 0.5px !important;
  word-spacing: 0.3px !important;
}
::placeholder {
  font-size: 14px;
}

.form-check-input:focus {
  box-shadow: none !important;
}
.offcanvas {
  transition: transform 0.3s ease-in-out;
}

.offcanvas.show {
  transform: translateX(0);
}

.offcanvas:not(.show) {
  transform: translateX(100%);
  transition: 0.5s;
  background-color: none !important;
  opacity: 0 !important;
}
.btn-close:focus {
  box-shadow: none !important;
}
.main-card-col {
  column-gap: 15px !important;
  color: #fff;
}
.main-card-col:first-child {
  /* column-gap: 15px !important; */
  color: #000;
}
.main-logo {
  width: 38%;
}
.hanuman-img {
  width: 65%;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.popup-btn {
  background-color: transparent;
  border: none;
}
.popup-title {
  font-size: 20px;
}
.bg-grey {
  background-color: #ccc;
}
.bg-grey-btn {
  background-color: #888;
}
.bg-main {
  background-color: var(--main-color);
  color: var(--main-text-color);
}
.bg-main-btn {
  background-color: var(--card-btn-color);
}

.invalid {
  color: red;
  font-size: 12px;
}
/* .country-drop {
  width: 20%;
} */
.race-mobile {
  background-color: #f0f0f0;
  /* padding: 0.5rem 1rem; */
  border: 1px solid #ccc;
}

.country-drop {
  /* padding: 0.5rem; */
  border: none;
  background-color: transparent;
  outline: none;
  width: 50%;
  font-size: 12px;
}

.race-category {
  flex-grow: 1;
  padding: 0.5rem;
  border: none;
  outline: none;
  background-color: transparent;
}

.race-details {
  display: block;
  margin-bottom: 0.5rem;
}

.rounded-3 {
  border-radius: 0.75rem;
}

.ul-class {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ul-class li {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.check-link {
  cursor: pointer !important;
}
.disable {
  background-color: #585858;
}
.sita-mata-img {
  width: 15%;
  overflow: hidden;
  border-radius: 5px;
  float: left;
  margin: 0px 10px;
}

/* Popup Css */
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for modern feel */
}

.popup_inner {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px 40px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  width: 400px;
  text-align: center;
}

.popup_inner p {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.btn-primary {
  background-color: var(--main-color);
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.header p {
  font-size: 26px;
  color: grey;
}
.reminder_msg{
  font-size: 18px;
}