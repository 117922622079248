.termsandcondition-header-text p {
  text-align: justify !important;
  margin-left: 20px !important;
  font-family: Arial !important;
  font-size: 18px;
  line-height: 25px !important;
  color: #888 !important;
  width: 80%;
}
.text-link {
  color: #0d6efd !important;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.popup-btn {
  background-color: transparent;
  border: none;
}
.refund-title {
  border-left: 4px solid red;
  letter-spacing: 0.5px;
  margin-bottom: 3px;
}

/* Payment recipt Css */
.payment-recipt {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
  border-radius: 8px;
  width: 50%;
}
.recipt-header {
  font-size: 24px !important;
  color: #333;
}
.main-recipt-logo {
  width: 39% !important;
}
.hanuman-payment-recipt {
  width: 33% !important;
}

.table tbody tr {
  border-bottom: 1px solid #dee2e6;
}

.table {
  border-collapse: collapse;
  width: 75% !important;
}
th p {
  margin: 0;
}
td p {
  margin: 0;
}
.table th {
  padding: 8px;
}
.table td {
  padding: 10px;
}
.imp-note {
  text-align: justify;
  font-size: 12px;
}
.table-text {
  color: rgb(2, 2, 70);
}

/* Flage Program css */
.flage-img {
  margin-bottom: 10px;
}
.ashokstambh-img {
  width: 144%;
}
.flag-title {
  font-size: 30px !important;
}

/* har ghar tiranga  css */
.quesation {
  font-size: 17px;
}
.main-tiranga-img {
  width: 70%;
}
.indian-flag-img {
  width: 30% !important;
}
/* top-btn css */
.top-btn {
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  position: fixed;
  bottom: 8%;
  right: 5%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  z-index: 99;
}
